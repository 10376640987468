import React from 'react'
import course from '../Image/course.png';
function Course() {
  return (
    <div>
     <section className="py-4 banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 my-auto">
                       <h6>維修王認證課程</h6>
                    </div>
                    <div className="col-md-8 my-auto">
                        <h6 className="float-end">
                  
                        </h6>
                    </div>
                </div>
            </div>
      </section>
      <section className="section border-bottom">
          <div className="container">
              <h6 className="main-heading">課程宗旨</h6>
              <div className='underline'></div>
                  <div className="aboutUs-contain">
                  <div className="aboutUs-Image">
                   <img src={course} className='img-fluid' alt="aboutUs"/>
                  </div>
                  <div className="aboutUs-infor">
                    <p>不論你是新入職或想轉行,從事家居維修確是一個不錯的選擇.一個信譽良優良的家居維修帥傅,月入可高達HK$40,000至HK$50,000.</p>
                    <p>除身酬不錯之外,工作自由度非常高,也是很多人夢寐以求的理想職業...</p>                    
                    <p>好可惜,這個項業入行不易.市面上未有正統課程供有志入行人事供讀.一般入行方去皆是要熟人介照,跟師傅邊學邊做.</p>
                    <p>這種傳統跟師方法,帶出以下問題:</p>
                    <ul>
                      <li>師父技術水平,參差不齊.學員好容易學壞師...</li>
                      <li>教導方式沒有系統,學員好難掌握...</li>
                      <li>由於家居維修項目繁多,要在短時間掌握實在不易,傳統師徒制,學成時間平均長度四年...</li>
                      <li>在傳統師徒制中,大部份教授過程都是在實際工作中完成,由於師傅邊做邊教,學徒只能從傍領會.因此缺乏實戰經驗,學習效果自然不理想...</li>
                    </ul>
                    <h6>突破傳統,創辦家居維修人員專業課程</h6>
                    <p>有見以上問題,我們創辦了全港首個家居維修人員專業課程,為有志入行的朋友,提供專業,有系統家居維修課程,課程有以下幾個優點:</p>
                    <ul>
                      <li>課程有別於市面的家居維修DIY班,課程主要教導專業的維修技巧,要求學員需在短時間完成專業維修服務.</li>
                      <li>所有導師不但擁有多年家居維修經驗,並且教學經驗豐富,令學員更易理解..</li>
                      <li>除了課堂學習外,我們提供家居維修實習場地供學員實習,務使學員在短時間內掌握家居維修技巧..</li>
                      <li>完成課程後,學員需進行維修王測試,訓利通過考核後,學員可正式成為維修王..</li>
                      <li>畢業學員,可使用我們研發的維修平台,接柯打..</li>
                    </ul>
                  </div>    
              
              </div>
          
          </div>        
      </section>
      <section className="section border-bottom">
          <div className="container">
              <h6 className="main-heading">課程內容</h6>
              <div className='underline'></div>
              <div className="aboutUs-contain">
                  <div className="aboutUs-infor">
                  
                  </div>    
                <div className="aboutUs-Image">
              
                </div>
              </div>
          
          </div>        
      </section>
    </div>    

  )
}

export default Course

