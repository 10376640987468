import './App.css';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from './Components/About';
import JoinUs from './Components/JoinUs';
import Course from './Components/Course';
import Findhelper from './Components/Findhelper';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footere from './Components/Footere';
import 'bootstrap/dist/css/bootstrap.css';
import { HashRouter as Router,Routes,Route } from 'react-router-dom';


function App() {
  return (
    <div>
      <Router>
        <Navbar/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="course" element = {<Course/>}/>
          <Route path="aboutUs" element={<About />} />
          <Route path="joinUs" element={<JoinUs />} />
          <Route path ="findHelper" element = {<Findhelper/>}/>
        </Routes>
        <Footere/>
      </Router>
    </div>
      
    
  );
}

export default App;
