import React from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-solid-svg-icons';

function MainService() {
  return (
       <section className="section bg-c-light border-top">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-4 text-center">
                    <h3 className="main-heading">服務</h3>
                    <div className="underline mx-auto"></div>
                </div>
                <div className="col-md-4 ">
                  <div className="card shadow">
                        <div className="card-body">
                            <h6><FontAwesomeIcon icon={faCertificate} />&nbsp;維修王證書課程</h6>
                            <div className="underline"></div> 
                              <p>全港首創:&nbsp;專業家居維修-技術員證書課程</p>                                
                              <ul>
                                <li>
                                    為有志從事家居維修行業的朋友,提供專業培訓課程.
                                </li>
                                <li>
                                    為家居維修的行內人員,提供劃一標準.
                                </li>
                                <li>  
                                    課程由在家居維修,裝修行業,逾十年經驗的負責人設計及教導...
                                </li>
                              </ul>
                            <Link to="/joinUs" className="btn btn-link">了解更多..</Link>
                        </div>        
                  </div>   
                  <br/>    
                </div>        
                <div className="col-md-4 ">
                  <div className="card shadow">
                        <div className="card-body">
                            <h6><FontAwesomeIcon icon={faHandshake}/>&nbsp;誠邀維修師傅加盟</h6>
                            <div className="underline"></div> 
                            <ul>
                                <li>為了劃一家居維修行業服務水平,我們誠邀有志提升本港家居維修水平的業內從業員加盟,成為註冊維修王.</li>      
                                <li>註冊維修王,可使用我們自家研發的維修王平台,誠接家居維修柯打.</li>   
                                <li>我們深信只要師傅的服務水平得到標準認證,將可提升客戶信心,從而增加師傅收入,達到雙赢局面</li>  
                            </ul>
                             <Link to="/joinUs" className="btn btn-link">了解更多..</Link>
                        </div>        
                    </div> 
                    <br/>  
                </div>           
                <div className="col-md-4 ">
                  <div className="card shadow">
                        <div className="card-body">
                            <h6><FontAwesomeIcon icon={faComment}/>&nbsp;家居維修服務</h6>
                            <div className="underline"></div> 
                            <ul>
                                <li>自家研發家居維修平台,用戶只需透過簡單操作,便可配對最合適的維修師傅.</li>      
                                <li>我們維修平台,是免滋擾的,用戶資料不會被公開.</li>     
                                <li>詳盡維修記錄,方便日後跟進.</li>  
                                <li>簡單註冊,便可免費享用.</li>  
                            </ul>
                            <Link to="/findHelper" className="btn btn-link">了解更多...</Link>
                        </div>        
                    </div>    
                </div>        
                <br/>
            </div>
        </div>
        </section>
        
  )
}

export default MainService
   