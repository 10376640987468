import React from 'react'
import HomeScreen from './HomeScreen'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainService from './MainService';


function Home() {
  return (
    <>
      <HomeScreen />
      
      <section className="section">
                <div className="container">
                    <div className="row">
                      <div className="col-md-12 ">
                            <div className="main-header">
                              <h3 className="main-heading">宗旨</h3>
                              <div className="underline mx-auto"></div>
                            </div>
                            <div className="mainContent">
                              <p>在香港擁有一個安樂窩並非易事,但隨著歲月流逝,家居出現大大少少的問題,小至水喉滴水,大至石屎剝落,均是無避免.
                                  以住在屋所附近,好容易找到相熟的維修師傅協肋維修,由於有維修師傅有回定舖頭,遇到維修後需要跟進,也好容易解決.但隨著環境改變,
                                  現時已不容易找到固定家居維修店舖,因此上網找維修師傅已成為新常態.</p>
                              <p>但維修師傅技術水平,維修態度參差不同,因此,客人及維修師傅不時出現爭執,而維修後也未能找到跟進方法.有見及此,我們從心出發,
                                  打造一個完善的家居維修平台,統一家居維修水平.....</p>
                            </div>
                            <div className="aboutBtn">
                                <Link to="/aboutUs" className="btn btn-warning shadow">了解更多..</Link>
                            </div>
                        </div>
                        
                     </div>
                </div>
        </section>
        <MainService/>
    </>
  )
}

export default Home


