import React from 'react'
import girl from '../Image/girl.png';
function About() {
  
  return (
    <div>
      <section className="py-4 banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 my-auto">
                       <h6>服務特點</h6>
                    </div>
                    <div className="col-md-8 my-auto">
                        <h6 className="float-end">
                  
                        </h6>
                    </div>
                </div>
            </div>
      </section>
      <section className="section border-bottom">
          <div className="container">
              <h6 className="main-heading">宗旨</h6>
              <div className='underline'></div>
                  <div className="aboutUs-contain">
                  <div className="aboutUs-Image">
                  <img src={girl} className='img-fluid' alt="aboutUs"/>
                </div>
                  <div className="aboutUs-infor">
                    <p>在香港擁有一個安樂窩並非易事,但隨著歲月流逝,家居出現大大少少的問題,小至水喉滴水,大至石屎剝落,均是無避免的問題.
                      以住在屋企樓下,好容易找到相熟的維修師傅協肋維修,由於有維修師傅有回定舖頭,遇到維修後需要跟進,也好容易解決.但隨著環境改變,
                      現時已不容易找到回定家居維修店舖,因此上網找維修師傅已成為新常態.</p>
                    <p>但維修師傅技術水平,維修態度參差不同,因此客人及維修師傅不時出現爭執,而維修後也未能找到跟進方法.有見及此,我們從心出發,
                      打造一個完善的家居維修平台,統一家居維修水平,令客戶更加安心.
                    </p>
                    <h7 id="heading">維修王-專業家居維修證書</h7>
                        <p>每一名參與維修王平台配對的師傅需先通過考核,確部達到專業水準.考核範圍涉及各家居維修項目.我們會跟據師傅評核結果,配給適合的維修項目.
                    </p>
                    <h7 id="heading">家居維修-技術員證書課程</h7>
                      <p>有意從事家居維修工作的朋友可報讀,課程會詳細教導每一個常見的家居維修項目.理論及實戰雙劍合壁.學員更有實習機會.完成課程後,需成功通過考核,方可成為維修王.</p>
                    <h7 id="heading">維修王平台</h7>
                      <p>我們的維修平台,是免滋擾式,用戶資料不會被公開,並詳盡保存維修記錄,方便日後跟進.經簡單註冊,便可免費享用.</p>
                </div>
               
              </div>
          </div>        
      </section>
    </div>    
  )
}

export default About
