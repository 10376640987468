import React from 'react';
import { Link } from 'react-router-dom';
import '../Footer.css';
function Footere() {
  return (
    <section className="section footer bg-dark text-white">
    <div className="container">
            <div className="row">
            <div className="col-md-3"></div>       
            <div className="col-md-3">
                <h6>快速連結</h6>
                <hr />
                <div><Link to="/">主頁</Link></div>
                <div><Link to="aboutUs">服務特點</Link></div>
                <div><Link to="/findHelper">如何找幫手?</Link></div>
                <div><Link to="/joinUs">成為維修王</Link></div>
                <div><Link to="/contact">聯絡我們</Link></div>      
            </div>
            <div className="col-md-3">
                <h6>聯絡資料</h6>
                <hr />
                <div><p className="text-white mb-1">地址:新蒲崗八達街9號,宏基中心二期-601室</p></div>
                <div><p className="text-white mb-1">電話:91031197</p></div>
                <div><p className="text-white mb-1">電郵:homerepairexpert@gmail.com</p></div>
            </div>
            <div className="col-md-3"></div>           
       </div>
    </div>
</section>

  )
}

export default Footere

