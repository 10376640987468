import React from 'react';
import mainscreen from '../Image/mainscreen.png';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
function HomeScreen() {
  return (
    <div className="mainScreen-Wrapper">
          <div className="mainScreen-Content">
            <ul>
                <li><FontAwesomeIcon icon={faCircleCheck} />&nbsp;提供一站式專業家居維修服務。</li>
                <li><FontAwesomeIcon icon={faCircleCheck}/>&nbsp;打造方便,易用,保障客戶私隱的專業維修平台。</li>
                <li><FontAwesomeIcon icon={faCircleCheck} />&nbsp;由經驗豐富的家居維修專家領導,為家居維修業界釐定專業標準。</li>
                <li><FontAwesomeIcon icon={faCircleCheck} />&nbsp;為有志從事家居維修行業的朋友,提供專業培訓課程。</li>
            </ul>
        </div>
    </div>
    
  )
}

export default HomeScreen

